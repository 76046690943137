<template>
  <div class="mb-3">
    <multiselect
      id="msCommodityClass"
      placeholder="Type to search available Commodity Classes"
      open-direction="bottom"
      label="name"
      track-by="id"
      :options="getCommodityClasses"
      :value="getSelectedCommodityClasses"
      @input="setSelectedCommodityClasses"
      @open="load('')"
      :multiple="true"
      :searchable="true"
      :loading="isSearching"
      :internal-search="true"
      :clear-on-select="false"
      :close-on-select="false"
      :limit="3"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="true"
      :hide-selected="false"
    >
      <template slot="option" slot-scope="props">
        <div class="option__desc">{{ customLabel(props) }}</div>
      </template>

      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          v-if="getSelectedCommodityClasses.length"
          @mousedown.prevent.stop="clearSelected(props.search)"
        ></div>
      </template>
      <span slot="noResult"
        >No Commodity Classes found. Consider changing the search query.</span
      >
    </multiselect>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const CommodityClassRepository = RepositoryFactory.get(
  "commodityClassRepository"
);

export default {
  props: ["userId", "customerId"],
  components: {
    Multiselect
  },
  data() {
    return {
      isSearching: false,
      isDirty: true
    };
  },
  async mounted() {
    //await this.load();
  },
  methods: {
    ...mapActions("companyMultiSelects", [
      "setCommodityClasses",
      "setSelectedCommodityClasses"
    ]),
    limitText(count) {
      return `and ${count} other ${this.companyType}`;
    },
    customLabel(props) {
      return `${props.option.name} (Id: ${props.option.id})`;
    },
    search: _.debounce(async function(query) {
      this.isDirty = true;
      await this.load(query);
    }, 350),

    async load(query) {
      if (!this.isDirty) {
        return;
      }

      this.isSearching = true;

      var requestData = {
        jwtToken: this.$store.state.user.jwtToken,
        customerId: this.customerId,
        userId: this.userId,
        division: this.getSelectedDivisions.map(a => a.source),
        billTo: this.getSelectedBillTos.map(a => a.id),
        consignee: this.getSelectedConsignees.map(a => a.id),
        shipper: this.getSelectedShippers.map(a => a.id),
        supplier: this.getSelectedSuppliers.map(a => a.id),
        searchTerm: query,
        skip: 0,
        take: 20
      };
      CommodityClassRepository.get(requestData)
        .then(response => {
          this.setCommodityClasses(response.data);

          this.isDirty = false;
        })
        .catch(error => {
             console.log(error);
        })
        .finally(() => {
          this.isSearching = false;
        });
    },
    clearSelected() {
      this.setSelectedCommodityClasses([]);
    }
  },
  computed: {
    ...mapGetters("companyMultiSelects", [
      "getCommodityClasses",
      "getSelectedCommodityClasses",
      "getSelectedDivisions",
      "getSelectedBillTos",
      "getSelectedConsignees",
      "getSelectedShippers",
      "getSelectedSuppliers"
    ])
  }
};
</script>

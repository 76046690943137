<template>
  <div class="mb-3">
    <multiselect
      :id="'ms' + companyType"
      :placeholder="'Type to search available ' + companyType"
      open-direction="bottom"
      label="name"
      track-by="abbreviation"
      :options="terminals"
      :value="selectedTerminals"
      @input="setSelectedTerminals"
      @search-change="search"
      @open="load('')"
      :multiple="true"
      :searchable="true"
      :loading="isSearching"
      :internal-search="false"
      :clear-on-select="false"
      :close-on-select="false"
      :limit="3"
      :limit-text="limitText"
      :max-height="600"
      :show-no-results="true"
      :hide-selected="false"
    >
      <template slot="clear" slot-scope="props">
        <div
          class="multiselect__clear"
          v-if="selectedTerminals && selectedTerminals.length > 0"
          @mousedown.prevent.stop="clearSelected(props.search)"
        ></div>
      </template>
      <span slot="noResult"
        >No {{ companyType }} found. Consider changing the search query.</span
      >
    </multiselect>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions, mapState } from "vuex";
import Multiselect from "vue-multiselect";
import { RepositoryFactory } from "@/services/RepositoryFactory";
const CompaniesRepository = RepositoryFactory.get("companiesRepository");

export default {
  props: ["userId", "customerId"],
  components: {
    Multiselect
  },
  data() {
    return {
      companyType: "Terminals",
      isSearching: false,
      isDirty: true
    };
  },
  async mounted() {
    //await this.load();
  },
  methods: {
    ...mapActions("companyMultiSelects", [
      "setTerminals",
      "setSelectedTerminals"
    ]),
    limitText(count) {
      return `and ${count} other ${this.companyType}`;
    },
    customLabel(props) {
      return `${props.option.name} (Id: ${props.option.id})`;
    },
    search: _.debounce(async function(query) {
      this.isDirty = true;
      await this.load(query);
    }, 350),

    async load(query) {
      if (!this.isDirty) {
        return;
      }

      this.isSearching = true;

      CompaniesRepository.getTerminals(query, 0, 100)
        .then(response => {
          this.setTerminals(response.data);

          this.isDirty = false;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.isSearching = false;
        });
    },
    clearSelected() {
      this.setSelectedTerminals([]);
    }
  },
  computed: {
    ...mapState("companyMultiSelects", ["terminals", "selectedTerminals"])
  }
};
</script>
